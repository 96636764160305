import * as React from 'react'
import styles from './Lockup.module.css'

export const Lockup: React.FC = () => {
  return (
    <svg
      className={styles['lockup']}
      viewBox="0 0 170 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M151.018 16.0692C154.557 16.0692 155.766 18.9531 156.236 21.1144H145.806C146.362 18.1655 148.133 16.0692 151.018 16.0692ZM151.998 30.3449C148.672 30.3449 146.454 28.1913 145.81 25.1449H160.957C160.957 25.1449 161.077 24.3839 161.077 23.4498C161.077 16.8568 157.75 12.0418 151.018 12.0418C144.822 12.0418 140.828 17.1712 140.828 23.2396C140.828 29.3084 144.314 34.4378 151.726 34.4378C157.453 34.4378 161.113 30.8467 161.113 30.8467L158.673 27.5811C158.673 27.5811 155.833 30.3449 151.998 30.3449ZM164.712 33.9594H169.748V5.05417H164.712V33.9594ZM135.778 4.60472C137.89 4.60472 140.316 5.16297 140.316 5.16297V9.44125C138.573 8.79118 133.722 7.43378 133.722 11.5588V12.4808H140.316V16.6289H133.722V33.9592H128.687V11.085C128.687 4.80458 133.664 4.60472 135.778 4.60472ZM121.489 4.60472C123.602 4.60472 126.027 5.16297 126.027 5.16297V9.44125C124.284 8.79118 119.434 7.43378 119.434 11.5588V12.4808H126.027V16.6289H119.434V33.9592H114.398V16.6289H110.676V12.4808H114.398V11.085C114.398 4.80458 119.376 4.60472 121.489 4.60472ZM102.705 12.4797H107.741V33.9582H102.705V31.0169C101.49 32.4511 99.2317 34.3969 95.9141 34.3969C90.6607 34.3969 87.9961 30.7285 87.9961 26.5105C87.9961 22.2921 87.9961 12.4797 87.9961 12.4797H93.0319C93.0319 12.4797 93.0319 22.7615 93.0319 25.0745C93.0319 27.3879 94.1602 29.7998 97.7486 29.7998C100.098 29.7998 101.714 28.3101 102.705 26.4564V12.4797ZM70.4821 29.2822H64.1382V10.7178H70.4821C75.6082 10.7178 79.3442 14.8739 79.3442 20.0003C79.3442 25.1268 75.6082 29.2822 70.4821 29.2822ZM70.4828 6.04224H59.0234V33.9611H70.4828C78.1921 33.9611 84.4418 27.7111 84.4418 20.0018C84.4418 12.292 78.1921 6.04224 70.4828 6.04224Z" />
      <path d="M0 10.0002V30.0002C2.76145 30.0002 5.00007 25.5229 5.00007 20C5.00007 14.4774 2.76145 10.0002 0 10.0002ZM9.51172 6.03906V33.9579C15.0346 33.9579 19.5119 27.7079 19.5119 19.9984C19.5119 12.2888 15.0346 6.03906 9.51172 6.03906ZM44.0234 20C44.0234 31.0458 35.0692 40 24.0234 40V0C35.0692 0 44.0234 8.95449 44.0234 20Z" />
    </svg>
  )
}
