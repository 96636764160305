import Link from 'next/link'
import * as React from 'react'
import { AnchorButton } from '@components/Button'
import { Card } from '@components/Card'

interface ForcePasswordResetCardProps {
  email?: string
}

export const ForcePasswordResetCard: React.FC<ForcePasswordResetCardProps> = ({
  email,
}) => {
  return (
    <Card className="force-password-reset-card">
      <h1>Password Reset Required</h1>
      <p>For your security, we ask that you reset your password.</p>
      <Link
        href={
          email ? `/reset-password?email=${btoa(email)}` : `/reset-password`
        }
        legacyBehavior
      >
        <AnchorButton
          intent="PRIMARY"
          text="Reset password now"
          iconAfter="arrow_right"
        />
      </Link>
      <style jsx>{`
        h1 {
          font-size: var(--FONT-SIZES-H1);
          font-weight: 400;
          margin: 0;
          line-height: 1.3;
        }

        p {
          line-height: 1.5;
          font-size: var(--FONT-SIZES-C1);
          margin: 16px 0px 32px 0;
          color: var(--grey-700);
        }

        :global(.force-password-reset-card) {
          text-align: center;
        }
      `}</style>
    </Card>
  )
}
