import { withRouter } from 'next/router'
import * as React from 'react'
import { Callout } from '@components/Callout'
import { IconName } from '@components/Icon'
import { WithRouterProps } from '@lib/types'
import { WorkspaceContext } from '@lib/workspace-context'

interface FlashAction {
  icon?: IconName
  code: string
  renderer: (
    context: React.ContextType<typeof WorkspaceContext>,
    data?: string
  ) => React.ReactNode | string
}

export const flashActionMap = {
  account_created: {
    code: 'account_created',
    renderer: (context) => (
      <React.Fragment>
        <b>Please confirm your email.</b> Can't find the email we sent you?{' '}
        <button
          onClick={async () => {
            await context.duffelClient.Identity.requestEmailConfirmation()
            context.addToast({
              intent: 'success',
              message: 'Confirmation email sent!',
            })
          }}
        >
          Resend it
        </button>
      </React.Fragment>
    ),
  } as FlashAction,
  email_confirmed: {
    code: 'email_confirmed',
    renderer: () => (
      <React.Fragment>
        <strong>Email confirmed!</strong> You can now log in below and start
        building.
      </React.Fragment>
    ),
  } as FlashAction,
  password_reset: {
    code: 'password_reset',
    renderer: () =>
      'Your password has been successfully updated. You can now sign in to Duffel as usual.',
  } as FlashAction,
  sign_in_for_invitation: {
    code: 'sign_in_for_invitation',
    renderer: () =>
      'You will be redirected to your invitation once you sign in.',
    icon: 'info',
  } as FlashAction,
}

export type FlashActions = keyof typeof flashActionMap

export interface FlashQueryProps {
  flash?: string
  flashStore?: string
}
export class FlashComponent extends React.Component<WithRouterProps> {
  static contextType = WorkspaceContext
  declare context: React.ContextType<typeof WorkspaceContext>

  maybeRenderFlash = (key?: string, data?: string) => {
    if (!key || !(key in flashActionMap)) return null

    const flash = flashActionMap[key] as FlashAction

    return (
      <div className="flash">
        <Callout iconName={flash.icon || 'check'}>
          {data
            ? flash.renderer(this.context, decodeURIComponent(data))
            : flash.renderer(this.context)}
        </Callout>

        <style jsx>{`
          .flash {
            margin-bottom: 16px;
          }

          .flash :global(a) {
            text-decoration: underline;
            cursor: pointer;
          }
        `}</style>
      </div>
    )
  }

  render() {
    const query = this.props.router.query
    const { flash, flashStore } = query as FlashQueryProps

    return this.maybeRenderFlash(flash, flashStore)
  }
}

export const Flash = withRouter(FlashComponent)
