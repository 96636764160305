import { Flash } from '@components/Flash'
import { Head } from '@components/Head'
import { EmptyLayout } from '@components/Layouts'
import { Lockup } from '@components/Lockup'
import { VSpace } from '@components/VSpace'
import { Text } from '@components/legacy-design-system/product/components/Text'
import { clearDashboardAuthPayloadCookie } from '@lib/authentication/clearDashboardAuthPayloadCookie'
import { isBrowser } from '@lib/env'
import { isSafeRedirect } from '@lib/helpers'
import { DuffelContext } from '@lib/types'
import { SignInFormContainer } from '@modules/identity-self'
import { ForcePasswordResetCard } from '@modules/identity-self/components/ForcePasswordResetCard'
import { NextPage } from 'next'
import absoluteUrl from 'next-absolute-url'
import Link from 'next/link'
import * as React from 'react'

const SignInPage: NextPage = () => {
  const [forcePasswordReset, setForcePasswordReset] = React.useState({
    shouldDisplayResetCard: false,
    email: '',
  })

  return (
    <EmptyLayout>
      <Head title="Sign in" />
      <div className="page">
        <div className="page__inner">
          <div className="page__header">
            <Lockup />
          </div>

          <VSpace space={32}>
            <Text
              typeStyle="heading2"
              asElement="h1"
              textAlign="center"
              color="grey-900"
            >
              Sign in to your account
            </Text>

            {forcePasswordReset.shouldDisplayResetCard && (
              <ForcePasswordResetCard email={forcePasswordReset.email} />
            )}
            {!forcePasswordReset.shouldDisplayResetCard && (
              <>
                <div className="page__content">
                  <Flash />
                  <SignInFormContainer
                    onRequirePasswordReset={(email) =>
                      setForcePasswordReset({
                        shouldDisplayResetCard: true,
                        email,
                      })
                    }
                  />
                </div>

                <div className="page__footer" data-selector="fs-show">
                  <Link href="/reset-password" passHref legacyBehavior>
                    <a>I've forgotten my password</a>
                  </Link>

                  <Link href="/join" passHref legacyBehavior>
                    <a>Create an account</a>
                  </Link>
                </div>
              </>
            )}
          </VSpace>
        </div>

        <style jsx>{`
          .page {
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 100vh;
            padding: 72px 24px;
            background-color: var(--grey-100);
            color: var(--purple-900);
          }

          .page__inner {
            max-width: 450px;
            width: 100%;
          }

          .page__header {
            text-align: center;
            margin-bottom: var(--space-64);
          }

          @media screen and (min-width: 1024px) {
            .page__header {
              position: absolute;
              top: var(--space-80);
              left: var(--space-128);
            }

            .page__title {
              margin-top: var(--space-64);
            }
          }

          .page__header > :global(.logo) {
            margin-left: auto;
            margin-right: auto;
          }

          .page__footer {
            margin-top: 32px;
            text-align: center;
          }

          .page__footer a {
            display: block;
            font-size: 16px;
            color: inherit;
            outline: none;
            padding-bottom: 2px;
            margin-bottom: 16px;
          }

          .page__footer a:last-child {
            margin-bottom: 0;
          }

          .page__footer a:hover,
          .page__footer a:focus {
            color: var(--purple-600);
          }
        `}</style>
      </div>
    </EmptyLayout>
  )
}

SignInPage.getInitialProps = async ({
  user,
  query,
  req,
  res,
}: DuffelContext) => {
  if (query.force === 'true') {
    clearDashboardAuthPayloadCookie(req, res)
    return {}
  }

  if (user) {
    const { next } = query || { next: null }
    const baseOrigin = isBrowser ? location.origin : absoluteUrl(req).origin
    if (next && !Array.isArray(next)) {
      if (isSafeRedirect(next, baseOrigin)) {
        const redirectUrl = new URL(decodeURIComponent(next), baseOrigin)
        return { redirect: redirectUrl.pathname }
      } else {
        return { redirect: '/' }
      }
    } else {
      return { redirect: '/' }
    }
  }
  return {}
}

export default SignInPage
