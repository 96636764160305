import { getCookieDomain, makePayloadCookie } from '@lib/cookies'
import { IncomingMessage, ServerResponse } from 'http'
import { addHeaderToServerResponse } from './addHeaderToServerResponse'

export function clearDashboardAuthPayloadCookie(
  req?: IncomingMessage,
  res?: ServerResponse
) {
  const expiredCookieString = makePayloadCookie(
    '',
    getCookieDomain(req, true),
    0,
    true
  )

  if (res) {
    addHeaderToServerResponse('Set-Cookie', [expiredCookieString], res)
  } else {
    document.cookie = expiredCookieString
  }
}
